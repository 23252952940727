import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const TextSection = styled.div`
  display: block;
  ${media.mq12`
    margin-top: ${(props) => `${props.theme.spacing[16]}`};
  `};
  ${media.mq34`
    margin-top: ${(props) => `${props.theme.spacing[24]}`};
  `};
`

export default TextSection
