import styled from 'styled-components'

import LinkExternal from '~components/LinkExternal'

const Link = styled(LinkExternal)`
  color: #427fed;
  &:hover {
    text-decoration: underline;
  }
`

export default Link
