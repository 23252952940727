import styled from 'styled-components'

import Typography from '~components/Typography'

const Label = styled(Typography)`
  ${({ theme, contrastText }) =>
    contrastText ? `color: ${theme.palette.text.primary.contrastText}` : ''};
`

export default Label
