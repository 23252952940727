import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'

import Typography from '~components/Typography'

import Label from '../UniversalFormComponents/Label'
import OptinalLabel from '../UniversalFormComponents/OptinalLabel'
import StyledErrorMessage from '../UniversalFormComponents/StyledErrorMessage'
import ErrorMessageWrapper from '../UniversalFormComponents/ErrorMessageWrapper'
import Wrapper from '../UniversalFormComponents/Wrapper'

import StyledTextarea from './StyledTextarea'

const InputField = ({
  label,
  helperText,
  name,
  optional,
  contrastText,
  ...props
}) => (
  <Wrapper>
    <Label htmlFor={name} as="label" contrastText={contrastText}>
      {label}
      {optional && (
        <OptinalLabel as="span" type="body4" contrastText={contrastText}>
          &nbsp;- optional
        </OptinalLabel>
      )}
    </Label>
    {helperText && <Typography>{helperText}</Typography>}
    <StyledTextarea name={name} id={name} {...props} />
    <ErrorMessageWrapper type="body4">
      <ErrorMessage name={name} component={StyledErrorMessage} />
      &nbsp;
    </ErrorMessageWrapper>
  </Wrapper>
)

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  contrastText: PropTypes.bool,
  optional: PropTypes.bool,
}

export default InputField
