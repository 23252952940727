import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const Wrapper = styled.section`
  display: grid;
  margin-top: ${(props) => props.theme.spacing[64]};
  ${media.mq34`
    grid-template-columns: 12fr 2fr 20fr;
    align-items: center;
  `};
`

export default Wrapper
