import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const TextWrapper = styled.div`
  ${media.mq12`
    padding: ${(props) =>
      `${props.theme.spacing[8]} ${props.theme.spacing[64]} ${props.theme.spacing[48]}`};
  `};
  ${media.mq34`
    padding: ${(props) =>
      `${props.theme.spacing[48]} 0 ${props.theme.spacing[48]} ${props.theme.spacing[64]}`};
  `};
`

export default TextWrapper
