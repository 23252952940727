import styled from 'styled-components'
import { Field, connect } from 'formik'

import { mapThemePropsToCss } from '~styles/helpers'

const StyledField = styled(Field)`
  border: ${({ formik, name, theme }) => {
    if (formik.errors[name] && formik.touched[name]) {
      return `${theme.spacing[1]} solid ${theme.palette.red.main}`
    }
    if (formik.values[name]) {
      return `${theme.spacing[1]} solid ${theme.palette.green.main}`
    }
    return `${theme.spacing[1]} solid transparent`
  }};
  padding: ${(props) => `${props.theme.spacing[8]} ${props.theme.spacing[16]}`};
  max-width: 20rem;
  border-radius: ${({ theme }) => theme.spacing[2]};
  ${({ theme }) => mapThemePropsToCss({ type: 'body2', theme })};
`

export default connect(StyledField)
