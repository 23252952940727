import styled from 'styled-components'

import { fluidSpacingFunc } from '~styles/helpers'

const Content = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  ${fluidSpacingFunc([{ property: 'padding', values: [4, 0] }])};
`

export default Content
