import React from 'react'
import PropTypes from 'prop-types'

import Layout from '~components/Layout'
import MaxWidthWrapper from '~components/MaxWidthWrapper'
import { NAVIGATION } from '~utils/constants'

import ContactForm from './ContactForm'
import Content from './Content'
import InfoText from './InfoText'
import ContactInfo from './ContactInfo'
import messages from './messages'

const metaTags = {
  title: 'Kontakt Personal Training in Zürich',
  description:
    'Brauchst du ein Coach im Bereich Fitness, Ernährung und Gesundheit. Buche jetzt ein Probetraining. Ich freue mich auf dich.',
  slug: NAVIGATION.CONTACT.CONTACT,
  image: {
    url: 'PageContact/HeroContact_01--large',
    alt: 'Laptop, Handy, Kaffeetasse und Kaffeekanne auf einem Tisch',
  },
}

const hero = {
  pictureData: {
    path: 'PageContact',
    alt: 'Laptop, Handy, Kaffeetasse und Kaffeekanne auf einem Tisch',
    fallback: {
      name: 'HeroContact_01--large',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MzE0IDI5ODkiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4NzgxNzkiIGQ9Ik0wIDBoNTMxNHYyOTY4SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC40IDEwLjQpIHNjYWxlKDIwLjc1NzgxKSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iIzFmMDcwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg2NC41NjgzNiAtMTcyLjAyMzc1IDM5LjY0NTcgMTQuODgwODQgMjU1IDQ3LjQpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgY3g9IjQ3IiBjeT0iMTAzIiByeD0iMTQiIHJ5PSIyMzEiLz48ZWxsaXBzZSBjeD0iMTYzIiBjeT0iNyIgcng9IjcwIiByeT0iMTgiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDE2LjY1NSAxNS4zNjg4IC0yNC40NDg4IDI2LjQ5NDkyIDk5LjEgMTE0KSIvPjxlbGxpcHNlIGZpbGw9IiNmZmYiIGN4PSIxNTgiIGN5PSIzNyIgcng9IjU0IiByeT0iMTQiLz48cGF0aCBmaWxsPSIjMTUwODAwIiBkPSJNMTY0IDg3bC00MiA0NCAxNDkgM3oiLz48ZWxsaXBzZSBmaWxsPSIjZTNmYmZmIiBjeD0iMjAwIiBjeT0iODUiIHJ4PSIyNSIgcnk9IjExIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEwNSA2NGg4M3YxNGgtODN6Ii8+PC9nPjwvc3ZnPg==',
    },
    breakpoints: {
      breakpointSmall: {
        name: 'HeroContact_01--small',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTkzIDI5ODkiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4Yjg4ODIiIGQ9Ik0wIDBoMTk5NnYyOTg5SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjggNS44KSBzY2FsZSgxMS42NzU3OCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoLTE3OS4zIDU4LjggOSkgc2NhbGUoODIuMjU3NTQgMjUuOTg1MTQpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMjcuNjI4NTYgLTI4LjYxMDIyIDQ3LjkyMDIgLTQ2LjI3NiA0Ni40IDIwMykiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC0xMjcuNiAxMjIuNiA1NS45KSBzY2FsZSg1NS4xNjY4OSAzMy4xOTcyNCkiLz48ZWxsaXBzZSBmaWxsPSIjZmVmZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC04OS42MDkxNiAtMTAuMDEzODIgMi4zOTMgLTIxLjQxMzkgMTM4LjcgNjcuMykiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiBjeD0iMTIwIiBjeT0iMTI2IiByeD0iNjgiIHJ5PSIxNSIvPjxlbGxpcHNlIGN4PSI4NCIgY3k9IjkwIiByeD0iNDgiIHJ5PSIxMCIvPjxlbGxpcHNlIGZpbGw9IiM4MDI2MDUiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoODQuOCAtNjEuOCAxOTEpIHNjYWxlKDI0LjQ4Mzg0IDU0LjU4NjI0KSIvPjxwYXRoIGZpbGw9IiNkMGUzZTYiIGQ9Ik0yMCAxNjRoMTEydjUySDIweiIvPjwvZz48L3N2Zz4=',
      },
      breakpointMedium: {
        name: 'HeroContact_01--medium',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOTg5IDI5ODkiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4NTgwNzkiIGQ9Ik0wIDBoMjk4OXYyOTg5SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjggNS44KSBzY2FsZSgxMS42NzU3OCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGN4PSIxODQiIGN5PSIxNyIgcng9IjE0MCIgcnk9IjI2Ii8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSg5Mi41IDU1IDExNS44KSBzY2FsZSgyMy44NzMxOSAxMTAuMDg0NDUpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtNTEuNDA3MDcgNDEuNzk3OTcgLTI2LjU1MDQ3IC0zMi42NTQyNiA2My44IDE5OS43KSIvPjxlbGxpcHNlIGZpbGw9IiMyMDA4MDAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoMTA3LjkgMzUuNSAxOTUuMykgc2NhbGUoNTAuMjE5MTcgMTAzLjM1OTc0KSIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yMTEgMTExbC0xNjIgNSAxOTUgNDd6Ii8+PHBhdGggZD0iTTEzMiAxNjBsNjEuNSA2NS45LTkuMS04Mi43LTE0NS45LTZ6TTQ5IDgxaDg3djE2SDQ5eiIvPjxlbGxpcHNlIGZpbGw9IiNkN2Y0ZjciIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMS43NTg0IDMwLjQ5NjA5IC0xNC43MzE4IC44NDk0MyAyMDkuMSAxNjguNykiLz48L2c+PC9zdmc+',
      },
      breakpointLarge: {
        name: 'HeroContact_01--large',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MzE0IDI5ODkiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4NzgxNzkiIGQ9Ik0wIDBoNTMxNHYyOTY4SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC40IDEwLjQpIHNjYWxlKDIwLjc1NzgxKSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iIzFmMDcwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg2NC41NjgzNiAtMTcyLjAyMzc1IDM5LjY0NTcgMTQuODgwODQgMjU1IDQ3LjQpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgY3g9IjQ3IiBjeT0iMTAzIiByeD0iMTQiIHJ5PSIyMzEiLz48ZWxsaXBzZSBjeD0iMTYzIiBjeT0iNyIgcng9IjcwIiByeT0iMTgiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDE2LjY1NSAxNS4zNjg4IC0yNC40NDg4IDI2LjQ5NDkyIDk5LjEgMTE0KSIvPjxlbGxpcHNlIGZpbGw9IiNmZmYiIGN4PSIxNTgiIGN5PSIzNyIgcng9IjU0IiByeT0iMTQiLz48cGF0aCBmaWxsPSIjMTUwODAwIiBkPSJNMTY0IDg3bC00MiA0NCAxNDkgM3oiLz48ZWxsaXBzZSBmaWxsPSIjZTNmYmZmIiBjeD0iMjAwIiBjeT0iODUiIHJ4PSIyNSIgcnk9IjExIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEwNSA2NGg4M3YxNGgtODN6Ii8+PC9nPjwvc3ZnPg==',
      },
    },
  },
  textData: {
    header: messages.header,
  },
}

const PageContact = ({ location }) => (
  <Layout
    hero={hero}
    pictureData={hero.pictureData}
    metaTags={metaTags}
    pathname={location.pathname}
  >
    <Content>
      <MaxWidthWrapper>
        <InfoText />
        <ContactForm />
      </MaxWidthWrapper>
    </Content>
    <MaxWidthWrapper>
      <ContactInfo />
    </MaxWidthWrapper>
  </Layout>
)

PageContact.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PageContact
