import styled from 'styled-components'
import PropTypes from 'prop-types'

import Typography from '~components/Typography'

const ErrorMessageWrapper = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`

ErrorMessageWrapper.defaultProps = {
  type: 'body4',
}

ErrorMessageWrapper.propTypes = {
  type: PropTypes.string,
}

export default ErrorMessageWrapper
