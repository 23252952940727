import React from 'react'
import { Formik } from 'formik'
import { string, object } from 'yup'

import Button from '~components/Button'
import { useActions } from '~store/useStore'
import { trackContactForm } from '~utils/tracking'

import messages from '../messages'

import InputField from './InputField'
import StyledForm from './StyledForm'
import Textarea from './Textarea'

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

const ContactForm = () => {
  const { addErrorMessage, addSuccessMessage } = useActions((dispatch) => ({
    addErrorMessage: (message) =>
      dispatch({
        type: 'addGlobalErrorMessage',
        payload: { message },
      }),
    addSuccessMessage: (message) =>
      dispatch({
        type: 'addGlobalSuccessMessage',
        payload: { message },
      }),
  }))

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        message: '',
        phone: '',
      }}
      validationSchema={object().shape({
        email: string()
          .email(messages.validEmail)
          .required(messages.requiredEmail),
        name: string().required(messages.requiredName),
        message: string().required(messages.requiredMessage),
        phone: string(),
      })}
      // TODO handle submission errors (global error messages)
      onSubmit={async (
        { email, name, message, phone },
        { resetForm, setSubmitting },
      ) => {
        trackContactForm()
        try {
          const response = await fetch('/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': 'application/json',
            },
            body: encode({
              'form-name': 'contact',
              email,
              name,
              message,
              phone,
            }),
          })
          if (response.status >= 200 && response.status < 300) {
            resetForm()
            setSubmitting(false)
            addSuccessMessage('Vielen Dank für deine Nachricht.')
            return Promise.resolve(response)
          }
          const error = new Error(response.statusText || response.status)
          error.response = response
          setSubmitting(false)
          addErrorMessage(
            'Leider ist eine unerwarteter Fehler aufgetreten, bitte versuche es erneut',
          )
          return Promise.reject(error)
        } catch (error) {
          addErrorMessage(
            'Leider ist eine unerwarteter Fehler aufgetreten, bitte versuche es erneut',
          )
          setSubmitting(false)
          console.log(error)
        }
        return undefined
      }}
    >
      {({ isSubmitting }) => (
        <StyledForm data-netlify="true" name="contact" method="POST">
          <InputField
            type="text"
            label={messages.name}
            name="name"
            contrastText
          />
          <InputField
            type="email"
            label={messages.email}
            name="email"
            contrastText
          />
          <InputField
            type="tel"
            label={messages.phone}
            name="phone"
            placeholder="+41 78 605 34 21"
            optional
            contrastText
          />
          <Textarea
            rows={8}
            name="message"
            label={messages.message}
            contrastText
          />
          <Button type="submit" disabled={isSubmitting}>
            {messages.submitButton}
          </Button>
        </StyledForm>
      )}
    </Formik>
  )
}

export default ContactForm
