const messages = {
  header: 'Kontakt',
  infoText:
    'Bist du bereit deine beste Version zu sein?\nZusammen bekommen wir das hin.',
  name: 'Name',
  email: 'E-Mail',
  phone: 'Natel',
  message: 'Deine Nachricht',
  submitButton: 'Absenden',

  requiredName: 'Teil mir bitte deinen Namen mit',
  requiredEmail: 'Um dich erreichen zu können gib bitte dein E-Mail Adresse an',
  validEmail: 'Kontrolliere nochmals ob sich ein Tippfehler eingeschlichen hat',
  requiredMessage: 'Bitte hinterlasse deine Nachricht hier',
}

export default messages
