import React from 'react'

import messages from '../messages'

import StyledInfoText from './StyledInfoText'

const InfoText = () => (
  <StyledInfoText type="header3" as="h3" align="center">
    {messages.infoText}
  </StyledInfoText>
)

export default InfoText
