import React from 'react'

import Typography from '~components/Typography'
import LinkExternal from '~components/LinkExternal'
import { CONTACT, MANA } from '~utils/constants'
import { removeWhiteSpace } from '~utils/helpers'
import { trackContactPhone, trackContactEmail, trackContactWebsite } from '~utils/tracking'

import TextWrapper from './TextWrapper'
import StyledTrapezoid from './StyledTrapezoid'
import TextSection from './TextSection'
import ContactLink from './ContactLink'

const Text = () => (
  <StyledTrapezoid>
    <TextWrapper>
      <Typography type="header3" as="h3">
        {MANA.NAME}
      </Typography>
      <TextSection as={LinkExternal} href={MANA.URL}>
        <Typography>{MANA.STREET}</Typography>
        <Typography>{MANA.CITY}</Typography>
      </TextSection>
      <TextSection>
        <ContactLink
          onClick={trackContactPhone}
          as="a"
          href={`tel:${removeWhiteSpace(CONTACT.PHONE)}`}
        >
          {CONTACT.PHONE}
        </ContactLink>
        <ContactLink
          onClick={trackContactEmail}
          as="a"
          href={`mailto:${CONTACT.EMAIL}`}
        >
          {CONTACT.EMAIL}
        </ContactLink>
      </TextSection>
      <TextSection>
        <ContactLink
          onClick={trackContactWebsite}
          as="a"
          href={MANA.URL}
        >
          {MANA.URL_SHORT}
        </ContactLink>
      </TextSection>
    </TextWrapper>
  </StyledTrapezoid>
)

export default Text
