import React from 'react'

import { MANA } from '~utils/constants'

import Link from './Link'

const InfoWindowContent = () => {
  return (
    <div>
      <strong>{MANA.NAME}</strong>
      <br />
      {MANA.STREET}
      <br />
      {MANA.CITY}
      <br />
      {MANA.COUNTRY}
      <br />
      <Link href={MANA.GOOGLE_MAPS_URL}>View on Google Maps</Link>
    </div>
  )
}

export default InfoWindowContent
