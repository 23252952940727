import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const Wrapper = styled.div`
  ${media.mq34`
    grid-row: 1;
    grid-column: 2 / 4;
  `};
`

export default Wrapper
