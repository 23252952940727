import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  height: auto;
  position: relative;
`

export const Triangle = styled.svg`
  position: absolute;
`

export const trianglePosition = {
  left: {
    top: css`
      height: 100%;
      top: 0;
      right: calc(100% - 0.2px);
    `,
  },
  right: {
    bottom: css`
      height: 100%;
      top: 0;
      left: calc(100% - 1px);
      transform: rotate(180deg);
    `,
  },
  top: {
    left: css`
      width: 100%;
      left: 0;
      bottom: calc(100% - 0.2px);
      transform: rotate(180deg);
    `,
    right: css`
      width: 100%;
      left: 0;
      bottom: calc(100% - 0.2px);
      transform: rotateX(180deg);
    `,
  },
}

const Trapezoid = ({ children, className }) => (
  <Wrapper className={className}>
    {children}
    <Triangle viewBox="0 0 100 100" preserveAspectRatio="none">
      <path d="M0 0l100 100V0z" />
    </Triangle>
  </Wrapper>
)

Trapezoid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Trapezoid
