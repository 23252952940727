import styled from 'styled-components'
import PropTypes from 'prop-types'

import Typography from '~components/Typography'

const StyledErrorMessage = styled(Typography)`
  color: ${({ theme }) => theme.palette.red.main};
`

StyledErrorMessage.defaultProps = {
  type: 'body4',
  as: 'span',
}

StyledErrorMessage.propTypes = {
  type: PropTypes.string,
  as: PropTypes.string,
}

export default StyledErrorMessage
