import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// category: string - required
// action: string - required
// label: string
// value: integer
// nonInteraction: bool
// transport: string
// hitCallback: function

export const trackEvent = ({ category, action, ...optional }) => {
  trackCustomEvent({
    category,
    action,
    ...optional,
  })
}

export const trackContactForm = () =>
  trackEvent({
    category: 'Contact Form',
    action: 'submit',
    label: 'Click (on "Absenden")',
  })

export const trackContactPhone = () =>
  trackEvent({
    category: 'Contact Phone',
    action: 'call',
    label: 'Click (on "+41 79 650 78 47")',
  })

export const trackContactEmail = () =>
  trackEvent({
    category: 'Contact E-Mail',
    action: 'send',
    label: 'Click (on "hello@tania-ferreira.com")',
  })

export const trackContactWebsite = () =>
  trackEvent({
    category: 'Contact Website',
    action: 'navigate',
    label: 'Click (on "https://www.mana-ernaehrung.ch/")',
  })
