import styled from 'styled-components'

import Typography from '~components/Typography'

const StyledInfoText = styled(Typography)`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.palette.text.primary.contrastText};
`

export default StyledInfoText
