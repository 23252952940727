import styled from 'styled-components'
import { Form } from 'formik'

import { media } from '~styles/mediaQuery'

const StyledForm = styled(Form)`
  ${media.mq1`
    margin: ${({ theme }) => `${theme.spacing[40]} 0`};
  `};
  ${media.mq234`
    margin: ${({ theme }) => `${theme.spacing[64]}`};
  `};
`

export default StyledForm
