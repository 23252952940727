import React, { useState } from 'react'
import {
  GoogleMap as ReactGoogleMap,
  Marker,
  useLoadScript,
  InfoWindow,
} from '@react-google-maps/api'

import { GATSBY_GOOGLE_MAPS_API_KEY, MANA } from '~utils/constants'

import InfoWindowContent from './InfoWindowContent'

const mapStyle = {
  width: '100%',
  height: '500px',
}

const mapCenter = {
  lat: 47.3773264,
  lng: 8.5317992,
}

const mapOptions = {
  id: MANA.NAME,
  googleMapsApiKey: GATSBY_GOOGLE_MAPS_API_KEY,
}

const markerLabel = {
  color: '#bf2113',
  text: MANA.NAME,
  fontWeight: 'bold',
}

const markerIcon = {
  scaledSize: {
    width: 27,
    height: 43,
    widthUnit: 'px',
    heightUnit: 'px',
  },
  labelOrigin: { x: 90, y: 20 },
  url:
    'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless2_hdpi.png',
}

const GoogleMap = () => {
  const { isLoaded } = useLoadScript(mapOptions)
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(true)

  return (
    isLoaded && (
      <ReactGoogleMap
        mapContainerStyle={mapStyle}
        center={mapCenter}
        clickableIcons={false}
        zoom={14}
        options={{
          disableDefaultUI: true,
          mapTypeControl: false,
          zoomControl: true,
          streetViewControl: false,
        }}
      >
        <Marker
          icon={markerIcon}
          position={mapCenter}
          onClick={() => setIsInfoWindowOpen(!isInfoWindowOpen)}
          label={markerLabel}
          zIndex={1}
        >
          {isInfoWindowOpen && (
            <InfoWindow onCloseClick={() => setIsInfoWindowOpen(false)}>
              <InfoWindowContent />
            </InfoWindow>
          )}
        </Marker>
      </ReactGoogleMap>
    )
  )
}

export default GoogleMap
