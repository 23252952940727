import React from 'react'

import GoogleMap from './GoogleMap'
import Wrapper from './Wrapper'

const Map = () => (
  <Wrapper>
    <GoogleMap />
  </Wrapper>
)

export default Map
