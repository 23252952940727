import React from 'react'

import Map from './Map'
import Wrapper from './Wrapper'
import Text from './Text'

const ContactInfo = () => (
  <Wrapper>
    <Map />
    <Text />
  </Wrapper>
)

export default ContactInfo
