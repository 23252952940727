import styled from 'styled-components'

import Typography from '~components/Typography'

const OptionalLabel = styled(Typography)`
  font-style: italic;
  ${({ theme, contrastText }) =>
    contrastText ? `color: ${theme.palette.text.secondary.contrastText}` : ''};
`

export default OptionalLabel
